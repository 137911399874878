import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import api from "../../utils/api";
import { useErrorContext } from "../../context/ErrorContext";
import { Navigate, useNavigate } from "react-router";
import { useAppContext } from "../../context/Context";
import Card from "../../components/Card"

const SubscriptionDetails = () => {
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { subscriptionData, setSubscriptionData } = useAppContext();

  const handleCancel = async () => {
    try {
      setIsLoading(true);
      const res = await api.post("/cancelSubscription", {
        subscriptionId: subscriptionData.id,
      });

      setIsLoading(false);
      setSubscriptionData((prevData) => ({
        ...prevData,
        status: "CANCELLED",
      }));
    } catch (error) {
      setIsLoading(false);
    }
  };

  const startDate = new Date(subscriptionData?.start_time).toLocaleDateString();
  const renewalDate = new Date(
    subscriptionData?.next_billing_time
  ).toLocaleDateString();

  const daysUntilRenewal = Math.ceil(
    (new Date(subscriptionData?.next_billing_time) - new Date()) /
      (1000 * 60 * 60 * 24)
  );
  const handleResume = async () => {
    navigate("/subscription");
    window.location.reload();
  };

  return (
    <Card>
      <h1 className="text-lg font-semibold text-gray-700">
        Subscription Details
      </h1>

      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <span className="text-base font-medium text-gray-600">
            Start Date:
          </span>
          <span className="text-base font-bold  text-gray-800">
            {startDate}
          </span>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-base font-medium text-gray-600">
            Payment Gateway:
          </span>
          <span className="text-base font-bold text-start text-gray-800">
            {subscriptionData?.payment_gateway}
          </span>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-lg font-medium text-gray-600">Status:</span>
          <span
            className={`px-3 font-bold py-2 text-sm  rounded-lg ${
              subscriptionData?.status === "ACTIVE"
                ? " bg-emerald-200 text-emerald-600"
                : subscriptionData?.status === "CANCELLED"
                ? "bg-rose-300 text-rose-500"
                : "bg-yellow-100 text-yellow-600"
            }`}
          >
            {subscriptionData?.status}
          </span>
        </div>

        {subscriptionData?.status === "ACTIVE" && (
          <div className="flex justify-between items-center">
            <span className="text-base font-medium text-gray-600 ">
              Renewal:
            </span>
            <span className="text-base font-bold text-start text-gray-800">
              {daysUntilRenewal} days to go
            </span>
          </div>
        )}

        {subscriptionData?.status === "ACTIVE" && (
          <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-sm font-medium text-gray-600">
                Next Billing Date:
              </span>
              <span className="text-sm text-gray-800">{renewalDate}</span>
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-end pt-4">
        {subscriptionData?.status === "ACTIVE" ? (
          <Button
            value="Cancel Subscription"
            type="danger"
            onClick={handleCancel}
            loading={loading}
          />
        ) : (
          <Button
            type="primary"
            loading={loading}
            value="Resume Subscription"
            onClick={handleResume}
          />
        )}
      </div>
    </Card>
  );
};

export default SubscriptionDetails;
