import React from "react";
import { useAppContext } from "../context/Context";
import { Navigate, Outlet } from "react-router";

const Unsubscribed = () => {
  const { userPlan } = useAppContext();

  return !userPlan ? <Outlet /> : <Navigate to="/" />;
};

export default Unsubscribed;
