import { createContext, useContext, useState, useEffect } from "react";
import api from "../utils/api";
import { getToken, getUser } from "../utils/localStorage";
import { useErrorContext } from "./ErrorContext";
import {
  fetchProductsService,
  defaultFetchState,
  updateLocalStorage,
} from "../utils/services";
const appContext = createContext();

export const ContextProvider = ({ children }) => {
  const [preview, setPreview] = useState();
  const [sidebar, setSidebar] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState(getUser());
  const [userPlan, setUserPlan] = useState(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [productState, setProductState] = useState(defaultFetchState);
  const [assignProducts, setAssignProducts] = useState([]);
  const [isUserUpdate, setIsUserUpdate] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [subscriptionDetailsLoading, setSubscriptionDetailsLoading] =
    useState(false);
  const { setStatus } = useErrorContext();

  //  Need to update the api
  //  cant get role in response

  const fetchUser = async () => {
    try {
      setStatus(true);
      const { data: user } = await api.get("/user");
      setUserData(user.data);
    } catch (error) {
      if (error.response.status === 500) {
        setStatus(false);
      }
    }
  };

  useEffect(() => {
    if (getToken()) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserUpdate]);

  const fetchProductsEffect = async () => {
    setProductState({ loading: true, data: [], error: null });
    const response = await fetchProductsService(userData.role);
    setProductState(response);
    if (response.error) {
      console.log("something went wrong");
    }
  };

  useEffect(() => {
    if (userData?.role) {
      fetchProductsEffect();
    }
  }, [userData]);

  const localStorageEffect = async (payload) => {
    const { data, error } = await updateLocalStorage(payload);
    if (!error) {
      setUserData({
        ...userData,
        localStorage: data,
      });
    }
  };

  const getLocalStorage = (target) => {
    return userData?.localStorage ? userData?.localStorage[target] : null;
  };
  return (
    <appContext.Provider
      value={{
        sidebar,
        setSidebar,
        userData,
        setUserData,
        setIsUserUpdate,
        isUserUpdate,
        userPlan,
        setUserPlan,
        subscriptionLoading,
        setSubscriptionLoading,
        setShowModal,
        showModal,
        preview,
        setPreview,
        setProductState,
        productState,
        assignProducts,
        setAssignProducts,
        fetchProductsEffect,
        localStorageEffect,
        getLocalStorage,
        setSubscriptionDetailsLoading,
        subscriptionDetailsLoading,
        setSubscriptionData,
        subscriptionData,
      }}
    >
      {children}
    </appContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(appContext);
};
